// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "bootstrap"
import "packs/custom/home_page"

import "@fortawesome/fontawesome-free/js/all";

require("jquery")
require("@nathanvda/cocoon")
require('popper.js');


import $ from 'jquery';
import 'select2';
global.$ = $
global.jQuery = $


require('jquery-ui');
import "chartkick/chart.js"
// import "bootstrap-multiselect"
// require.context('file-loader?name=[path][name].[ext]&context=node_modules/jquery-ui-dist!jquery-ui-dist', true, /jquery-ui\.css/);
// require.context('file-loader?name=[path][name].[ext]&context=node_modules/jquery-ui-dist!jquery-ui-dist', true, /jquery-ui\.theme\.css/);

import "packs/custom/chosen.jquery"

